import React, { useEffect } from "react";
import { getFilmRoomContent, getFilmRoomPlans } from '@store/actions'
import { useDispatch, useSelector } from 'react-redux'
import NoRecord from '@components/NoRecord'
// import FilmContentSelectedPlanCard from '@components/FilmContentSelectedPlanCard'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import httpClient from '@utils/httpClient'
import { SET_LOADER, SET_TOAST } from '@store/actions'
import { Button, Dialog, DialogContent, Divider, Grid } from '@mui/material'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { useState } from 'react'
import FilmContentPlanCard from "../../components/FilmContentPlanCard";
import { getOrgData } from '@store/actions'
import {
    DASHBOARD_PAGE,
} from '@routes/constants'
import { dateFormat } from '../../utils/functions'
import { getMe, getHistoricPlan } from '@store/actions'

const Container = styled(Card)({
    display: 'flex',
    flexDirection: 'row',
    flex: '1 0 auto',
    width: '100%',
    textDecoration: 'none',
    padding: 10,
    cursor: 'pointer',
})
const ContainerContent = styled(CardContent)({
    flex: '1 1 auto',
})
const BucketArrow = styled(Box)({
    display: 'flex',
    alignItems: 'center',
})


export const Subscription = () => {
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.user || {})
    const { userPlan } = useSelector(
        (state) => state.user || []
    );
    // const showLoader = useSelector((state) => state.theme.showLoader)
    const { plans, contents: fileRoomContents } = useSelector(
        (state) => state.fileRoomContent
    )
    const org = useSelector((state) =>
        state.org.org ? state.org.org.data : {}
    )
    const navigate = useNavigate()
    const [subShow, setSubShow] = useState(false)
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!org) {
                    await dispatch(getOrgData())
                } else {
                    dispatch({ type: SET_LOADER, data: true })
                    await dispatch(getOrgData())
                    dispatch(getMe())
                    dispatch(getHistoricPlan())
                    dispatch(getFilmRoomPlans())
                    if (user.plan_subscription) {
                        dispatch(getFilmRoomContent())
                    } else {
                        dispatch(getFilmRoomPlans())
                    }
                }
            } catch (error) {
            }
        };
        fetchData();
    }, [dispatch]);


    const handleDontUpdatePlan = () => {
        setSubShow(false)
    }

    const handleCancelSubsc = async () => {
        if (!user?.plan_subscription?.planSubscriptionId) {
            setOpen(false)
            return;
        }
        if (user?.plan_subscription?.status === 'CANCELED') {
            setOpen(false)
            dispatch({
                type: SET_TOAST,
                data: {
                    type: 'error',
                    message:
                        'Your Current plan is already Canceled',
                },
            })
            return;
        }
        dispatch({ type: SET_LOADER, data: true })
        const apiRes = await httpClient.post('/cancel-plan-subscriptions', {
            planSubscriptionId: user?.plan_subscription?.planSubscriptionId,
        })
        if (apiRes && apiRes.ok) {
            dispatch({ type: SET_LOADER, data: false })
            dispatch(getMe())
            setOpen(false)
            navigate(DASHBOARD_PAGE)
        } else {
            dispatch({ type: SET_LOADER, data: false })
            setOpen(false)
            dispatch({
                type: SET_TOAST,
                data: {
                    type: 'error',
                    message:
                        apiRes.message ||
                        'Something went wrong. Please try again!',
                },
            })
        }

    }
    return (
        <>
            {(user?.plan_subscription?.planDetail?.id) && plans.length > 0 ? plans.map((plan) => (
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={plan.id}
                >
                    {
                        plan.id === user?.plan_subscription?.planDetail?.id &&
                        <>
                            <Typography variant="h3" sx={{ mb: 2, mt: 2 }}>
                                Current Plan:
                            </Typography>
                            <Divider sx={{ mb: 2 }}></Divider>
                            <Container>
                                <ContainerContent>
                                    <Typography
                                        component="div"
                                        variant="h4"
                                        sx={{ display: 'flex' }}
                                    >
                                        {plan.title}
                                    </Typography>
                                    <Typography variant="body1" sx={{ my: 1 }}>
                                        {plan.description}
                                    </Typography>
                                    <Typography variant="h3">
                                        {plan.amount_currency} {plan.amount}
                                    </Typography>
                                    <Typography variant="body1">
                                        Expire Date: {dateFormat(user?.plan_subscription?.end_date)}
                                        <Typography variant="span" sx={{ color: 'red' }}>  {user?.plan_subscription?.status !== 'CANCELED' ? " (Auto recurring)" : ''} </Typography>
                                    </Typography>
                                </ContainerContent>
                                <BucketArrow>
                                    {(user?.plan_subscription?.status === 'COMPLETED' || user?.plan_subscription?.status === "paid") ? <IconButton aria-label="show detail">
                                        <CheckCircleRoundedIcon fontSize="large" sx={{ color: "green" }} />
                                    </IconButton> :
                                        <IconButton aria-label="show detail">
                                            <CancelRoundedIcon fontSize="large" sx={{ color: "red" }} />
                                        </IconButton>}
                                </BucketArrow>
                            </Container>
                            <Box>
                                <Button variant="contained" sx={{ mr: 2, mt: 2 }} onClick={() => setSubShow(true)}>Change Subscription Plan</Button>
                                {(user?.plan_subscription?.status === 'COMPLETED' || user?.plan_subscription?.status === "paid") ? <Button variant="outlined" sx={{ mt: 2 }} onClick={handleOpen}>Cancel Subscription</Button> : ""}
                            </Box>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-describedby="alert-dialog-slide-description"
                            >
                                <DialogContent>
                                    <Typography sx={{ color: "red", mb: 2 }}>Are you sure you want to cancel your Subscription? This will<br /> remove your access to exclusive content</Typography>
                                    <Button variant='contained' sx={{ backgroundColor: "red", mr: 2 }} onClick={handleCancelSubsc}>Yes, Cancel Subscription</Button>
                                    <Button variant='outlined' onClick={handleClose} sx={{ color: "black", borderColor: "black" }}><b>No, DO NOT CANCEL!</b></Button>
                                </DialogContent>
                            </Dialog>
                        </>
                    }
                </Grid>
            )) : <NoRecord sx={{ mt: 20 }}>
                You do not have any subscribed plan.
            </NoRecord>}

            {
                !(user?.plan_subscription?.planDetail?.id) && plans.length > 0 &&
                <>
                    <Typography variant="h3" sx={{ mt: 2 }}>
                        Choose Plan to Continue
                    </Typography>

                    {plans.map((plan) => (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            key={plan.id}
                        >
                            {
                                <>
                                    <Grid item xs={12} sx={{ pt: 2 }}>
                                        <Grid
                                            container
                                            spacing={4}
                                            columns={{ xs: 12, sm: 6, md: 4 }}
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                key={plan.id}
                                                sx={{ mb: 2 }}
                                            >
                                                <FilmContentPlanCard plan={plan} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    ))}
                </>
            }
            {
                subShow &&
                <>
                    <Typography variant="h3" sx={{ mt: 2 }}>
                        Subscription Options
                    </Typography>
                    <Divider></Divider>
                    <Grid item xs={12} sx={{ pt: 2 }}>
                        <Grid
                            container
                            spacing={4}
                            columns={{ xs: 12, sm: 12, md: 12 }}
                        >
                            {plans?.map((plan) => (
                                (plan.id !== user?.plan_subscription?.planDetail?.id) && <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    key={plan.id}
                                    sx={{ mb: 2 }}
                                >
                                    <FilmContentPlanCard plan={plan} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Button variant="outlined" sx={{ mr: 5 }} onClick={handleDontUpdatePlan}>Dont Want to Change Subscription</Button>
                </>
            }
            {
                <>
                    <Typography variant="h3" sx={{ mt: 3, mb: 2 }}>
                        Historic Subscription Plan :
                    </Typography>


                    {userPlan?.map((plan) => (
                        <>
                            <Box>
                                <Container>
                                    <ContainerContent>
                                        <Typography
                                            component="div"
                                            variant="h4"
                                            sx={{ display: 'flex' }}
                                        >
                                            {plan.plan_title}
                                        </Typography>
                                        <Typography variant="body1" sx={{ my: 1 }}>
                                            {plan.plan_description}
                                        </Typography>
                                        <Typography variant="h3">
                                            {plan.plan_amount_currency} {plan.plan_amount}
                                        </Typography>
                                        <Typography variant="body1" sx={{ my: 1 }}>
                                            Status:  {plan.status}
                                        </Typography>
                                        <Typography variant="body1">
                                            Start Date: {dateFormat(plan?.start_date)}
                                        </Typography>
                                        <Typography variant="body1">
                                            Expire Date: {dateFormat(plan?.end_date)}
                                        </Typography>

                                    </ContainerContent>
                                </Container >
                            </Box>
                            <Divider sx={{ mb: 2 }}></Divider>
                        </>
                    ))}

                </>
            }
        </>
    )
}