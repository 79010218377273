import * as actionTypes from '@store/actions'

export const initialState = {
    contents: [],
    categories: [],
    content: {}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_PLAYER_CONTENT: {
            return {
                ...state,
                contents: action.data,
            }
        }
        case actionTypes.UPDATE_PLAYER_CONTENT: {
            const contents = [...state.contents]
            const findIndex = contents.findIndex((x) => x.id === action.data.id)
            contents[findIndex] = action.data
            return {
                ...state,
                contents,
            }
        }
        case actionTypes.ADD_PLAYER_CONTENT: {
            return {
                ...state,
                contents: [action.data, ...state.contents],
            }
        }
        case actionTypes.SET_PLAYER_CONTENT_CATEGORY: {
            return {
                ...state,
                categories: action.data,
            }
        }
        case actionTypes.UPDATE_PLAYER_CONTENT_CATEGORY: {
            const categories = [...state.categories]
            const { parent_category, ...rest } = action.data
            if (parent_category) {
                const catIndex = categories.findIndex(
                    (cat) => cat.id === Number(parent_category)
                )
                if (catIndex !== -1) {
                    const findIndex = categories[
                        catIndex
                    ].sub_category.findIndex((x) => x.id === rest.id)
                    categories[catIndex].sub_category[findIndex] = rest
                }
            } else {
                const findIndex = categories.findIndex((x) => x.id === rest.id)
                categories[findIndex] = rest
            }
            return {
                ...state,
                categories,
            }
        }
        case actionTypes.ADD_PLAYER_CONTENT_CATEGORY: {
            let categories = [...state.categories]
            const { parent_category, ...rest } = action.data
            if (parent_category) {
                const catIndex = categories.findIndex(
                    (cat) => cat.id === Number(parent_category)
                )
                if (catIndex !== -1) {
                    categories[catIndex].sub_category.push(rest)
                } else {
                    categories = [rest, ...categories]
                }
            } else {
                categories = [rest, ...categories]
            }
            return {
                ...state,
                categories,
            }
        }
        case actionTypes.ADD_PLAYER_SESSION: {
            return {
                ...state,
            }
        }
        case actionTypes.SET_PLAYER_SESSION_ID: {
            return {
                ...state,
                content: action.data[0],
            }
        }
        default: {
            return state
        }
    }
}

export default reducer
