import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'

const CustomGrid = styled(Grid)(({ theme }) => ({
    height: '160px',
    background: theme.palette.background.default,
    color: theme.palette.primary.main,
}))

const NoRecord = (props) => {
    return (
        <CustomGrid
            container
            justifyContent="center"
            alignItems="center"
            sx={props.sx ? { ...props.sx } : {}}
        >
            {props.children}
        </CustomGrid>
    )
}

export default NoRecord
