import * as actionTypes from '@store/actions'

export const initialState = {
    posts: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SOCIAL_POST: {
            return {
                ...state,
                posts: action.data,
            }
        }
        case actionTypes.SET_SOCIAL_POSTID: {
            return {
                ...state,
                postdata: action.data,
            }
        }
        case actionTypes.CREATE_SOCIAL_POST: {
            return {
                ...state,
                // posts: action.data,
            }
        }
        case actionTypes.CREATE_SOCIAL_COMMENT: {
            return {
                ...state,
                // posts: action.data,
            }
        }
        case actionTypes.CREATE_SOCIAL_LIKE: {
            return {
                ...state,
                // posts: action.data,
            }
        }
        case actionTypes.SET_SOCIAL_COMMENT_LIST: {
            return {
                ...state,
                commentsList: action.data.comments,
            }
        }
        default: {
            return state
        }
    }
}

export default reducer
