import * as actionTypes from '@store/actions'
import httpClient from '@utils/httpClient'

export const initialState = {
    org: null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ORG:
            httpClient.defaults.headers.common['X-ORG-TOKEN'] = `${
                action.data ? action.data.token || '' : null
            }`
            return {
                ...state,
                org: action.data.ok ? action.data : null,
            }
        default:
            return state
    }
}

export default reducer
