import * as actionTypes from '@store/actions'

export const initialState = {
    events: [],
    locations: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_PLAYER_EVENT: {
            return {
                ...state,
                events: action.data,
            }
        }
        case actionTypes.ADD_PLAYER_EVENT: {
            return {
                ...state,
                events: [action.data, ...state.events],
            }
        }
        case actionTypes.EDIT_PLAYER_EVENT: {
            const events = [...state.events]
            const findIndex = events.findIndex((x) => x.id === action.data.id)
            events[findIndex] = action.data
            return {
                ...state,
                events,
            }
        }
        case actionTypes.SET_EVENT_LOCATIONS: {
            return {
                ...state,
                locations: action.data,
            }
        }

        case actionTypes.ADD_EVENT_LOCATIONS: {
            return {
                ...state,
                locations: [action.data, ...state.locations],
            }
        }

        case actionTypes.UPDATE_EVENT_LOCATIONS: {
            const locations = [...state.locations]
            const findIndex = locations.findIndex((x) => x.id === action.data.id)
            locations[findIndex] = action.data
            return {
                ...state,
                locations,
            }
        }

        default: {
            return state
        }
    }
}

export default reducer
