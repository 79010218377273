import { useDispatch, useSelector } from 'react-redux'

import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'

import { styled } from '@mui/material/styles'

import { SET_NOTIFICATION_CONTENT } from '@store/actions'

const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .title': {
        fontSize: '26px',
        margin: '15px 15px 0px 15px',
    },
    '& .content': {
        margin: '0px 15px 25px 15px',
        fontSize: '16px',
    },
}))

const NotificationPopup = () => {
    const dispatch = useDispatch()
    const content = useSelector((state) => state.notification.content)
    const org = useSelector((state) =>
        state.org.org ? state.org.org.data : {}
    )

    const handleClose = () => {
        setTimeout(() => {
            dispatch({ type: SET_NOTIFICATION_CONTENT, content: null })
        }, 300)
    }

    const postedBy = () => {
        return `${content.postedBy}`.replace('{{RG_SP_ORG_NAME}}', org.title)
    }

    return (
        <CustomDialog open={!!content} onClose={handleClose}>
            <DialogTitle className="title">
                {content ? content.title || '' : ''}
            </DialogTitle>
            <DialogContent>
                <DialogContentText className="content">
                    {content ? content.body || '' : ''}
                </DialogContentText>
                {content && content.postedBy && (
                    <Typography variant="h4" className="content" align="right">
                        {postedBy()}
                    </Typography>
                )}
            </DialogContent>
        </CustomDialog>
    )
}

export default NotificationPopup
