import { useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import LinkUI from '@mui/material/Link'

import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import InstagramIcon from '@mui/icons-material/Instagram'
import TiktokIcon from '@components/Icons/Tiktok'

const SocialIcon = () => {
    const org = useSelector((state) =>
        state.org.org ? state.org.org.data : {}
    )

    return (
        <Box
            sx={{
                width: '250px',
                display: 'flex',
                textAlign: 'center',
                justifyContent: 'space-evenly',
            }}
        >
            {org.fb_url && (
                <LinkUI
                    color="inherit"
                    target="_blank"
                    href={org.fb_url}
                    title="Facebook"
                >
                    <FacebookIcon sx={{ color: '#4267b2' }} />
                </LinkUI>
            )}
            {org.twitter_url && (
                <LinkUI
                    color="inherit"
                    target="_blank"
                    href={org.twitter_url}
                    title="Twitter"
                >
                    <TwitterIcon sx={{ color: '#1DA1F2' }} />
                </LinkUI>
            )}
            {org.instagram_url && (
                <LinkUI
                    color="inherit"
                    target="_blank"
                    href={org.instagram_url}
                    title="Instagram"
                >
                    <InstagramIcon sx={{ color: '#8a3ab9' }} />
                </LinkUI>
            )}
            {org.tiktok_url && (
                <LinkUI
                    color="inherit"
                    target="_blank"
                    href={org.tiktok_url}
                    title="TikTok"
                    sx={{ width: 24, height: 24 }}
                >
                    <TiktokIcon sx={{ color: '#8a3ab9' }} />
                </LinkUI>
            )}
        </Box>
    )
}

export default SocialIcon
