import * as actionTypes from '@store/actions'

export const initialState = {
    open: false,
    content: null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_NOTIFICATION_CONTENT:
            return {
                ...state,
                content: action.content,
            }
        default:
            return state
    }
}

export default reducer
