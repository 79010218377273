import * as actionTypes from '@store/actions'

export const initialState = {
    buckets: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_BUCKET:
            return {
                ...state,
                buckets: action.data,
            }
        case actionTypes.UPDATE_BUCKET:
            const buckets = [...state.buckets]
            const bucketIndex = buckets.findIndex(
                (x) => x.id === action.data.id
            )
            buckets[bucketIndex] = action.data
            return {
                ...state,
                buckets,
            }
        default:
            return state
    }
}

export default reducer
