import { TextField, styled } from '@mui/material';

const CustomTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        boxShadow: '4px 5px 11px rgba(162, 188, 244, 0.4)',
        border: 'none',
        padding: '1px',
        borderRadius: '4px',
        borderColor: 'transparent'
    },
    '& .MuiInputLabel-root': {
        display: 'none',
    },
    'fieldset': {
        border: 'none',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
            boxShadow: 'none',
        },
    },
    '& .MuiInputBase-input::placeholder': {
        color: 'black', // Set the desired placeholder font color
    },
});

export default CustomTextField;