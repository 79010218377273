import * as actionTypes from '@store/actions'

export const initialState = {
    plans: [],
    contents: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_FILM_ROOM_CONTENT: {
            return {
                ...state,
                contents: action.data,
            }
        }
        case actionTypes.SET_FILM_ROOM_PLANS: {
            return {
                ...state,
                plans: action.data,
            }
        }
        default: {
            return state
        }
    }
}

export default reducer
