import { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useTheme, styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import Logo from '@components/Logo'
import ContactForm from '@components/ContactForm'
import ChangePassword from '@components/ChangePassword'
import * as pages from '@routes/constants'
import SocialIcons from '@components/SocialIcon'
import { LOGOUT_USER, SET_BUCKET } from '@store/actions'

const ListCustom = styled(List)({
    width: '250px',
    marginTop: '40px',
    '& a, & div': {
        textAlign: 'center',
        '& span': {
            fontWeight: 'bold',
        },
    },
})
const LogoutButton = styled(Button)(({ theme }) => ({
    background: theme.palette.primary.dark,
    padding: '6px 70px',
}))
const DrawerMenu = ({ drawerOpen, drawerToggle }) => {
    const theme = useTheme()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [showForm, setShowForm] = useState(null)
    const org = useSelector((state) =>
        state.org.org ? state.org.org.data : {}
    )

    const logout = () => {
        dispatch({ type: SET_BUCKET, data: [] })
        dispatch({ type: LOGOUT_USER })
        closeHandler()
        navigate(pages.ROOT_PAGE)
    }

    const closeHandler = () => {
        setShowForm(null)
        drawerToggle()
    }

    return (
        <Drawer
            anchor={'top'}
            open={drawerOpen}
            onClose={closeHandler}
            sx={{
                '& .MuiDrawer-paper': {
                    minHeight: '80vh',
                    background: theme.palette.primary.light,
                    color: theme.palette.text.primary,
                },
            }}
        >
            <Grid
                container
                alignItems="center"
                direction="column"
                sx={{ flex: 1 }}
            >
                <Grid
                    item
                    xs={12}
                    container
                    justifyContent="center"
                    alignItems="center"
                >
                    <Logo size="small" />
                    <Box
                        sx={{
                            position: 'absolute',
                            right: '20px',
                            top: '20px',
                            cursor: 'pointer',
                        }}
                        onClick={closeHandler}
                    >
                        <CloseIcon fontSize="large" />
                    </Box>
                </Grid>
                <Grid container sx={{ pl: 2, pr: 2 }}>
                    <Grid item xs={showForm ? 3 : 12}>
                        <Grid
                            item
                            xs={12}
                            container
                            justifyContent={showForm ? 'flex-start' : 'center'}
                            alignItems="center"
                        >
                            <ListCustom>
                                <ListItem disablePadding>
                                    <ListItemButton
                                        component={Link}
                                        to={pages.DASHBOARD_PAGE}
                                        onClick={closeHandler}
                                    >
                                        <ListItemText primary={'Dashboard'} />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem
                                    disablePadding
                                    divider={true}
                                    sx={{ paddingBottom: '10px' }}
                                >
                                    <ListItemButton
                                        onClick={() =>
                                            setShowForm('change-password')
                                        }
                                    >
                                        <ListItemText
                                            primary={'Change Password'}
                                        />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem
                                    disablePadding
                                    divider={true}
                                    sx={{ paddingBottom: '10px' }}
                                >
                                    <ListItemButton
                                        component={Link}
                                        to={pages.SUBSCRIPTION_PAGE}

                                        onClick={() => {
                                            setShowForm('subscription-plan');
                                            closeHandler()
                                        }
                                        }
                                    >
                                        <ListItemText
                                            primary={'Subscription Plan'}
                                        />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem
                                    disablePadding
                                    sx={{ paddingTop: '10px' }}
                                >
                                    <ListItemButton
                                        onClick={() =>
                                            setShowForm('contact-us')
                                        }
                                    >
                                        <ListItemText primary={'Contact Us'} />
                                    </ListItemButton>
                                </ListItem>
                            </ListCustom>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            container
                            justifyContent={showForm ? 'flex-start' : 'center'}
                            alignItems="center"
                        >
                            <SocialIcons />
                        </Grid>
                    </Grid>
                    {showForm && (
                        <Grid item xs={9} justifyContent="center">
                            {showForm === 'contact-us' && <ContactForm />}
                            {showForm === 'change-password' && (
                                <ChangePassword />
                            )}
                        </Grid>
                    )}
                </Grid>
                {!showForm && (
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="center"
                        alignItems="flex-end"
                        sx={{ marginTop: 'auto', marginBottom: '30px' }}
                    >
                        <LogoutButton variant="contained" onClick={logout}>
                            Logout
                        </LogoutButton>
                    </Grid>
                )}
            </Grid>
        </Drawer>
    )
}

DrawerMenu.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
}

export default DrawerMenu
