import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { createTransform, persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import reducers from './reducers'
import httpClient from '@utils/httpClient'

const persistConfig = {
    key: 'root',
    storage,
    // blacklist: [],
    whitelist: ['user'],
    transforms: [
        createTransform(
            // transform state on its way to being serialized and persisted.
            (inboundState, reducerKey) => {
                if (reducerKey === 'user' && inboundState.token) {
                    httpClient.defaults.headers.common[
                        'Authorization'
                    ] = `Bearer ${inboundState.token}`
                }
                return { ...inboundState }
            },
            // transform state being rehydrated
            (outboundState, reducerKey) => {
                return { ...outboundState }
            },
            // define which reducers this transform gets called for.
            { whitelist: ['user'] }
        ),
    ],
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = createStore(
    persistedReducer,
    applyMiddleware(thunk) // add any middlewares here
)

const persistor = persistStore(store)

export { store, persistor }
