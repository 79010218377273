import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'

const LoaderWrapper = styled('div')({
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1301,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgb(0 0 0 / 30%)',
})

const Loader = () => (
    <LoaderWrapper>
        <CircularProgress color="primary" />
    </LoaderWrapper>
)

export default Loader
