import { createTheme } from '@mui/material/styles'

import componentStyleOverrides from '@themes/compStyleOverride'
import themePalette from '@themes/palette'
import themeTypography from '@themes/typography'

import colors from '@assets/scss/_themes-vars.module.scss'

export const theme = (customization = {}) => {
    const updatedColors = {
        ...colors,
        ...(customization.colors ? customization.colors : {}),
    }
    const color = updatedColors

    const themeOption = {
        colors: updatedColors,
        heading: color.grey900,
        paper: color.paper,
        backgroundDefault: color.paper,
        background: color.primaryLight,
        darkTextPrimary: color.grey700,
        darkTextSecondary: color.grey500,
        textDark: color.grey900,
        menuSelected: color.secondaryDark,
        menuSelectedBack: color.secondaryLight,
        divider: color.grey200,
    }

    const themeOptions = {
        direction: 'ltr',
        palette: themePalette(themeOption),
        // mixins: {
        //     toolbar: {
        //         minHeight: '48px',
        //         padding: '16px',
        //         '@media (min-width: 600px)': {
        //             minHeight: '48px',
        //         },
        //     },
        // },
        typography: themeTypography(themeOption),
    }

    const themes = createTheme(themeOptions)
    themes.components = componentStyleOverrides(themeOption)

    return themes
}

export default theme
