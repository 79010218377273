import * as actionTypes from '@store/actions'

export const initialState = {
    saveAction: false,
    clearAction: false,
    svgData: null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SAVE_ACTION:
            return {
                ...state,
                saveAction: action.data,
            }
        case actionTypes.SET_CLEAR_ACTION:
            return {
                ...state,
                clearAction: action.data,
            }
        case actionTypes.SAVE_SVGDATA:
            return {
                ...state,
                svgData: action.data,
            }
        default:
            return state
    }
}

export default reducer
